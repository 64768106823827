<template>
  <section v-if="!slice.primary.hide_section" class="home__multi-callout">
    <div class="row">
      <div v-for="callout in callouts" class="column small-12 large-6">
        <div class="callout-card">
          <div class="row gutter-small">
            <div class="column small-6 large-12 xlarge-6">
              <PrismicLazyImage
                :image="callout.image"
                :link="callout.cta_link"
                width="615"
                height="400"
              />
            </div>
            <div class="column small-6 large-12 xlarge-6">
              <span v-if="callout.label" class="h5">{{ callout.label }}</span>
              <PrismicLink :link="callout.cta_link"><h2 v-if="callout.title" class="h4"><prismic-rich-text :field="callout.title" /></h2></PrismicLink>
              <ArrowLink v-if="callout.cta_link && callout.cta_text" :link="callout.cta_link" :text="callout.cta_text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { fetchHomepageCalloutById } from '@/prismic/queries';

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import PrismicLink from '@/components/prismic/PrismicLink';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'HomepageMultiCallout',

    components: {
      PrismicLazyImage,
      PrismicLink,
      ArrowLink
    },

    data() {
      return {
        callouts: null
      }
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },

    async fetch() {
      const calloutIds = [this.slice.primary.callout_1.id, this.slice.primary.callout_2.id];

      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchHomepageCalloutById,
          variables: {
            calloutIds: calloutIds
          }
        });

        const response = data.allHomepage_callouts.edges;

        if (response.length) {
          this.callouts = response.map(item => item.node);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
</script>
